import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Gallery from "./Gallery";
import Information from "./Information";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";

const Landing_Comps = () => {
  const { target } = useParams();
  const [tour, setTour] = useState(null);
  useEffect(() => {
    if (tour === null) {
      axios
        .get(`https://vragio-backend.benspace.xyz/api/tour/${target}`)
        .then((result) => {
          setTour(result.data.tour);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return tour === null ? (
    <div className="wrapper flex w-full h-full justify-center items-center  ">
      <CircularProgress />
    </div>
  ) : (
    <section className="section px-16 my-8">
      <div className="wrapper flex flex-row mb-4 items-center justify-between">
        <div className="breadcrumbs ">
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{ fontFamily: "Poppins" }}
          >
            <Link to={"/destination"} className="font-medium">
              Destinasi
            </Link>{" "}
            <Link className="font-semibold text-black">{tour.name}</Link>
          </Breadcrumbs>
        </div>
      </div>
      <div className="content mt-14 mb-10">
        <Gallery />
      </div>
      <div className="information">
        <Information tour={tour} />
      </div>
    </section>
  );
};
export default Landing_Comps;
